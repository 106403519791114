
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import useTranslation from 'next-translate/useTranslation'
import Button from '../components/atoms/button'
import Container from '../components/atoms/container'
import GridContainer from '../components/atoms/grid-container'
import GridItem from '../components/atoms/grid-item'
import Heading from '../components/atoms/heading'
import Link from '../components/atoms/link'
import LoadableImage from '../components/atoms/loadable-image'
import Paragraph from '../components/atoms/paragraph'
import {FooterDataStateProps, FooterProvider} from '../contexts/footer-context'
import PageLayout from '../layouts/page-layout'
import defaultImageLoader from '../lib/default-image-loader'
import fetchGeneralSettings from '../lib/fetch-general-settings'
import fetchSocialLinks from '../lib/fetch-social-links'
import bishopSymbol from '../public/assets/bishop-logo-symbol.png'
import notFoundIllustration from '../public/assets/not-found-illustration.png'

export default function NotFound({social, general}: FooterDataStateProps) {
  const {t} = useTranslation('common')

  return (
    <FooterProvider value={{social, general}}>
      <PageLayout
        disableDefaultPadding
        className="grid grid-flow-row py-28 md:py-44 gap-16 md:gap-24"
        title={t('not-found.title')}
        description={t('not-found.description')}
        openGraph={{
          images: [{url: bishopSymbol.src}],
          url: "404",
        }}
        nofollow
        noindex
      >
        <Container>
          <GridContainer
            disableDefaultColumns
            className="items-center grid-flow-row md:grid-flow-col grid-cols-1 md:grid-cols-2 gap-8 md:gap-0"
          >
            <GridItem className="grid gap-4 order-2 md:order-1">
              <Heading>{t('not-found.title')}</Heading>

              <Paragraph fontSize="text-xl">
                {t('not-found.description')}
              </Paragraph>

              <div className="grid grid-flow-col gap-4 items-center place-content-start">
                <Button
                  variant="primary"
                  className="justify-self-start"
                  renderAsLink
                  href="/contact-us"
                >
                  {t('common:navigation.contact-us')}
                </Button>

                <Link
                  href="/"
                  active
                  shouldDisplayActiveUnderline={false}
                  className="hover:underline"
                >
                  {t('not-found.home-link')}
                </Link>
              </div>
            </GridItem>

            <GridItem className="order-1 md:order-2 md:mb-0">
              <LoadableImage
                src={notFoundIllustration}
                alt="Small snooping dark figures standing behind bushes"
                layout="responsive"
                width={558}
                height={380}
                objectFit="contain"
                priority
                loader={defaultImageLoader}
              />
            </GridItem>
          </GridContainer>
        </Container>
      </PageLayout>
    </FooterProvider>
  )
}

 function _getStaticProps() {
  return {
    props: {
      social: fetchSocialLinks(),
      general: fetchGeneralSettings(),
    },
  }
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  